import React from 'react';
import Layout from '../components/layout/layout';
import Hero from '../components/hero/hero';
import './contact.scss';

const Contact = () => (
  <Layout>
    <Hero title="Nous Contacter"/>
    <main className="container mb-3 py-3">
      <div className="row mb-3">
        <div className="col-12 col-md-4">
          <div className="card">
            <div className="card-body">
              <h3>Papillon Bleu Dakar</h3>
              <dl className="row">
                <dt className="col-12 col-lg-3">Adresse</dt>
                <dd className="col-12 col-lg-9">À côté de la poste de Pikine</dd>

                <dt className="col-12 col-lg-3">Email</dt>
                <dd className="col-12 col-lg-9">centrepapillonbleu@gmail.com</dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8">
          <figure className="figure"/>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-4">
          <div className="card">
            <div className="card-body">
              <h3>Vous avez une question ?</h3>
              <p>
                Vous pouvez nous contacter via ce formulaire à n'importe quel moment. Vous pouvez être sûr que nous vous
                recontaterons dans les plus bref délais.
              </p>
            </div>
          </div>
        </div>
        <iframe title="Google contact form"
          className="col-12 col-md-8"
          src="https://docs.google.com/forms/d/e/1FAIpQLSf7-Bqs8qdYSeUbXOnkEKkgGtCB2nJOaUX0e9ftyphpchhtNQ/viewform?embedded=true"
          width="700"
          height="1200"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading...
        </iframe>
      </div>
    </main>
  </Layout>
);

export default Contact;
